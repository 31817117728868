 /*
  * Lightbox gallery
  */

 $('.block-gallery').lightGallery({
     selector: '.post-gallery-item',
     share: true,
     download: true,
     rotate: false,
     showThumbByDefault: true,
     loop: false,
     slideEndAnimatoin: false,
     thumbnail: true,
     allowMediaOverlap: true,
     toggleThumb: true,
     youtubePlayerParams: {
         loadYoutubeThumbnail: true,
         youtubeThumbSize: 'default',
         modestbranding: 1,
         showinfo: 0,
         rel: 0
     }
 });

 /*
  * Owl carousel
  */

 $('.owl-block').owlCarousel({
     margin: 20,
     items: 3,
     loop: true,
     autoplay: true,
     autoplayTimeout: 4500,
     nav: true,
     dots: false,
     navText: ["<i class='icon-arrow-left'></i>", "<i class='icon-arrow-right'></i>"],
     // navContainer: '#customNav', 
     responsive: {
         0: {
             items: 1
         },
         700: {
             items: 1,
         },
         1200: {
             items: 3,
         },
         1600: {
             items: 4,
         }
     }
 });

 $('.owl-testimonails').owlCarousel({
     margin: 40,
     items: 4,
     loop: true,
     autoplay: true,
     autoplayTimeout: 4500,
     nav: true,
     dots: false,
     navText: ["<i class='icon-arrow-left'></i>", "<i class='icon-arrow-right'></i>"],
     // navContainer: '#customNav', 
     responsive: {
         0: {
             items: 1
         },
         700: {
             items: 1,
         },
         1200: {
             items: 4,
         },
         1600: {
             items: 4,
         }
     }
 });


 $('.owl-logos').owlCarousel({
     margin: 20,
     items: 4,
     loop: true,
     autoplay: true,
     autoplayTimeout: 4500,
     nav: false,
     dots: false,
     navText: ["<i class='icon-angle-left'></i>", "<i class='icon-angle-right'></i>"],
     // navContainer: '#customNav', 
     responsive: {
         0: {
             items: 3
         },
         700: {
             items: 2,
             margin: 0,
         },
         1200: {
             items: 4,
         }
     }
 });


 var owl = $("#teacher-carousel").owlCarousel({
     items: 1,
     loop: false,
     autoplay: false,
     autoplayTimeout: 4500,
     nav: false,
     dots: false,
     navText: ["<i class='icon-arrow-left'></i>", "<i class='icon-arrow-right'></i>"],
     //  navContainer: '#customNavTeacher',
 });

 // Dodajte događaj za promenu slajda kada kliknete na predavača
 $(".teacher-thumbnail").click(function () {
     var slideIndex = $(this).data("slide-index");
     owl.trigger("to.owl.carousel", slideIndex);
     updateActiveClass(slideIndex);
 });

 // Dodajte događaj za praćenje promena u Owl Carouselu i ažuriranje active klase
 owl.on('changed.owl.carousel', function (event) {
     updateActiveClass(event.item.index);
 });

 // Prilagodite događaje za custom dots
 $('.custom-dots .dot').click(function () {
     owl.trigger('to.owl.carousel', [$(this).index(), 300]);
 });

 // Praćenje trenutnog slajda za označavanje aktivnog custom dot-a
 owl.on('changed.owl.carousel', function (event) {
     $('.custom-dots .dot').removeClass('active');
     $('.custom-dots .dot').eq(event.item.index).addClass('active');
 });

 // Klik na prethodni taster
 $('.custom-nav .prev').click(function (e) {
     e.preventDefault();
     owl.trigger('prev.owl.carousel');
 });

 // Klik na sledeći taster
 $('.custom-nav .next').click(function (e) {
     e.preventDefault();
     owl.trigger('next.owl.carousel');
 });

 // Funkcija za ažuriranje active klase na teacher-thumbnail
 function updateActiveClass(index) {
     $(".teacher-thumbnail").removeClass("active");
     $(".teacher-thumbnail[data-slide-index=" + index + "]").addClass("active");
 }