(function ($) {
  $(function () {
    $(".mobile-menu .menu-item-has-children > a").after('<i class="icon-angle-down sub-menu-show"></i>');

    $(".mobile-menu .menu-item-has-children > i").on("click", function () {
      $(this).siblings(".sub-menu").toggleClass("active");
      $(this).toggleClass("icon-angle-down icon-angle-up");
    });

    // Init AOS
    AOS.init();

    // Scroll on click
    $(document).on("click", ".scroll", function (event) {
      event.preventDefault();

      $("html, body").animate(
        {
          scrollTop: $($.attr(this, "href")).offset().top - 167,
        },
        500
      );
    });

    // Registration modal
    $('.btn-registration-modal, .btn[href="#"], footer [href="#"]').on("click", function (e) {
      e.preventDefault();
      console.log("click modal");
      $("#registration-modal").modal("show");
    });

    /*
     * Navbar fixed
     */
    // $(window).on('scroll', function () {
    //   if ($(this).scrollTop() > 63) {
    //     $('.site-header').addClass('navbar-fixed-top');
    //   } else {
    //     $('.site-header').removeClass('navbar-fixed-top');
    //   }
    // });

    /*
     * Toggle burger menu on mobile
     */
    $(".menu-btn-burger").on("click", function () {
      $("#nav-icon").toggleClass("open-custom-menu");
      $(".mobile-menu").toggleClass("sliding-open");
    });

    $(".mob-menu-close").on("click", function () {
      $("#nav-icon").toggleClass("open-custom-menu");
      $(".mobile-menu").toggleClass("sliding-open");
    });

    /*
     * Owl carousel
     */
    $(".owl-angebot").owlCarousel({
      margin: 0,
      items: 3,
      loop: true,
      autoplay: true,
      autoplayTimeout: 4500,
      nav: true,
      dots: false,
      center: true,
      animateOut: "fadeOut",
      navText: ["<i class='icon-arrow-left'></i>", "<i class='icon-arrow-right'></i>"],
      // navContainer: '#customNav',
      responsive: {
        0: {
          items: 1,
        },
        700: {
          items: 2,
          margin: 0,
        },
        1200: {
          items: 3,
        },
      },
    });

    /*
     * Video modal
     */
    var player;

    function onYouTubeIframeAPIReady() {
      player = new YT.Player("video-iframe", {
        events: {
          onStateChange: onPlayerStateChange,
        },
      });
    }

    function onPlayerStateChange(event) {
      if (event.data == YT.PlayerState.PLAYING) {
        $("#play-video-modal").hide();
      }
    }

    // Click on image
    $("body").on("click", "[id^='play-video-modal-']", function (e) {
      e.preventDefault();

      var modalNumber = $(this).attr("id").split("-").pop();

      $("#video-modal-" + modalNumber).modal("show");

      // YouTube API script
      var tag = document.createElement("script");
      tag.src = "https://www.youtube.com/iframe_api";
      var firstScriptTag = document.getElementsByTagName("script")[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    });

    // close modal
    $(".video-modal").on("hidden.bs.modal", function (e) {
      $(".video-modal iframe").attr("src", $(".video-modal iframe").attr("src"));
    });

    /*
     * Lightbox gallery
     */
    $(".gallery-images").lightGallery({
      selector: ".gallery-item",
      share: true,
      download: true,
      rotate: false,
      showThumbByDefault: true,
      loop: false,
      slideEndAnimatoin: false,
      thumbnail: true,
      allowMediaOverlap: true,
      toggleThumb: true,
      youtubePlayerParams: {
        loadYoutubeThumbnail: true,
        youtubeThumbSize: "default",
        modestbranding: 1,
        showinfo: 0,
        rel: 0,
      },
    });

    $(".counter").counterUp({
      delay: 10,
      time: 2000,
    });

    // Postavi datum za odbrojavanje
    const targetDate = new Date("January 4, 2024 00:00:00").getTime();

    // Ažuriraj tajmer svaki sekund
    const x = setInterval(function () {
      // Trenutni datum i vreme
      const now = new Date().getTime();

      // Računanje razlike između ciljanog datuma i trenutnog datuma
      const distance = targetDate - now;

      // Računanje dana, sati, minuta i sekundi
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // Dodavanje nula za vrednosti od 1 do 9
      const daysDisplay = days < 10 ? `0${days}` : days;
      const hoursDisplay = hours < 10 ? `0${hours}` : hours;
      const minutesDisplay = minutes < 10 ? `0${minutes}` : minutes;
      const secondsDisplay = seconds < 10 ? `0${seconds}` : seconds;

      // Postavljanje vrednosti u odgovarajuće HTML elemente
      document.getElementById("days").innerHTML = `<span class="days">${daysDisplay}</span> Tage`;
      document.getElementById("hours").innerHTML = `<span class="hours">${hoursDisplay}</span> Stunden`;
      document.getElementById("minutes").innerHTML = `<span class="minutes">${minutesDisplay}</span> Minuten`;
      document.getElementById("seconds").innerHTML = `<span class="seconds">${secondsDisplay}</span> Sekunden`;

      // Ako je vreme isteklo, prikaži poruku
      if (distance < 0) {
        clearInterval(x);
        document.getElementById("countdown").innerHTML = "Vreme je isteklo!";
      }
    }, 1000); // svake sekunde

    /**
     * * Search Form - FAQ Page
     *
     */
    if ($('#searchform-faq').length) {
      $("#searchform-faq").submit(function (event) {
        event.preventDefault(); // Prevent the form from submitting normally
        const searchQuery = $("#search").val(); // Get the search query
        const ajaxUrl = event.target.dataset.ajaxurl;

        $.ajax({
          type: "POST",
          url: ajaxUrl,
          data: {
            action: "search_cpt",
            query: searchQuery,
          },
          success: function (response) {
            $("#faqs").html(response); // Display the search results

            // Manually update the active category info if needed
            var firstTab = $("#faqTabs a").first();
            if (firstTab.length) {
              firstTab.tab("show"); // Bootstrap method to show the tab
              // Alternatively, manually trigger the update for the first tab
              var activeCategoryName = firstTab.text().trim();
              var activeCategoryDescription = firstTab.data("description").trim();

              $("#activeCategoryName").text(activeCategoryName);
              $("#activeCategoryDescription").text(activeCategoryDescription);
            }

            return false;
          },
        });
      });

      // Function to update active category name and description
      function updateActiveCategoryInfo(name, description) {
        $("#activeCategoryName").text(name);
        $("#activeCategoryDescription").text(description);
      }

      $("body").on("shown.bs.tab", "#faqTabs a", function (e) {
        var activeCategoryName = $(e.target).text().trim();
        var activeCategoryDescription = $(e.target).data("description").trim();

        $("#activeCategoryName").text(activeCategoryName);
        $("#activeCategoryDescription").text(activeCategoryDescription);
      });

      // Initialize the display with the first tab's data if it's active by default
      var initialCategoryName = $("#faqTabs a.active").text().trim();
      var initialCategoryDescription = $("#faqTabs a.active").data("description").trim();
      updateActiveCategoryInfo(initialCategoryName, initialCategoryDescription);
    }
  });
})(jQuery);
